//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'ColorPointsHeader',
  props: {
    message: Object,
    color: String
  },
  computed: {
    cssVars () {
      return {
        '--color': this.color.replace('a', '').replace(', 0.5', '')
      }
    }
  },
  methods: {
    pointsClass () {
      switch (this.color) {
        case 'rgba(227, 13, 104, 0.5)' :
          return 'pink'
        case 'rgba(190, 206, 35, 0.5)' :
          return 'green'
      }
    }
  }
}
